<template>
    <marketing>
        <marketing-hero>
            <p class="section-header text-center text-muted mb-3">
                {{ $t('pages.marketing.privacy.header') }}
            </p>
            <h3 class="fw-bold mb-3">
                {{ $t('pages.marketing.privacy.title') }}
            </h3>
            <h5 class="fw-light text-muted mb-0 px-lg-4">
                {{ $t('pages.marketing.privacy.subTitle') }}
            </h5>
        </marketing-hero>
        <section-block class="pt-0">
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 col-lg-6 offset-lg-3">
                        <list-button
                            :title="$t('pages.marketing.privacy.dataUse')"
                            image-src="global/DownloadBooklet.svg"
                            href="/docs/DataUsePolicy.pdf"
                        />
                        <list-button
                            :title="$t('pages.marketing.privacy.consumerPrivacy')"
                            image-src="global/DownloadBooklet.svg"
                            href="/docs/ConsumerPrivacyPolicyNotice.pdf"
                        />
                        <list-button
                            :title="$t('pages.marketing.privacy.ccbPrivacy')"
                            image-src="global/DownloadBooklet.svg"
                            href="https://www.coastalbank.com/privacy-notice.html"
                        />
                    </div>
                </div>
            </div>
        </section-block>
    </marketing>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'
    import ListButton from '@/components/ListButton'
    import MarketingHero from '@/components/MarketingHero'

    export default {
        name: 'Privacy',
        metaInfo: {
            title: 'Our Privacy Policy',
            meta: [{ name: 'description', vmid: 'description', content: 'We value your privacy and want you to know how we collect, share, and use information.' }],
        },
        mounted: function () {
            this.$logEvent('view_privacy')
        },
        components: {
            SectionBlock,
            Marketing,
            ListButton,
            MarketingHero,
        },
    }
</script>
